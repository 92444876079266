import React, { FC } from 'react';
import { Headline, Paragraph } from '_atoms';
import { Statistic } from '../types';
import classNames from 'classnames';

export const NumericalStatistic: FC<Statistic> = ({
    title,
    value,
    unit,
    showUnit,
    text,
}) => {
    return (
        <div className="flex flex-grow overflow-y-auto items-center gap-3 rounded-2xl bg-neutral-100 p-5">
            <div className="pl-1.5 pr-4">
                <Headline
                    Level={'h3'}
                    className="text-center pt-0"
                    color="contrast"
                >
                    {value}
                </Headline>

                {showUnit && unit && (
                    <Headline
                        Level={'h5'}
                        className="text-center pb-1"
                        color="contrast"
                    >
                        {/*TODO: Add translations*/}
                        {unit}
                    </Headline>
                )}
            </div>
            <div className="flex flex-col gap-2 text-primary-1">
                {/*TODO: Add translations*/}
                <Paragraph weight="semibold">{title}</Paragraph>
                <Paragraph
                    className="w-full text-ellipsis line-clamp-2"
                    color="light"
                >
                    {/*TODO: Add translations if necessary*/}
                    {text}
                </Paragraph>
            </div>
        </div>
    );
};

export const NumericalStatisticSkeleton: FC<{ index: number }> = ({
    index,
}) => {
    return (
        <div className="flex flex-grow overflow-y-auto items-center gap-3 rounded-2xl bg-neutral-100 p-5">
            <div className="flex flex-col animate-pulse bg-neutral-100 dark:bg-neutral-400 rounded-3xl w-12 h-full p-4" />
            <div className="flex flex-col gap-2 w-full text-primary-1 border-red">
                <div
                    className={classNames(
                        'animate-pulse bg-neutral-100 dark:bg-neutral-400 h-3 rounded-full',
                        (index + 1) % 4 === 1 && 'w-2/5',
                        (index + 1) % 4 === 2 && 'w-4/5',
                        (index + 1) % 4 === 3 && 'w-2/5',
                        (index + 1) % 4 === 0 && 'w-3/5',
                    )}
                />
                <div className="animate-pulse bg-neutral-100 dark:bg-neutral-400 h-10 rounded-lg " />
            </div>
        </div>
    );
};
