import React, { FC, useMemo, useState } from 'react';
import { ResultsProps } from '../../Results';
import { ProfileInfo } from '../../RiskAnalysisV2/ProfileInfo';
import { DefaultPageLayout } from '../../../../components/Layout/DefaultPageLayout';
import { useTranslation } from 'react-i18next';
import { Target } from '../../../../services/dataService';
import {
    NumericalStatistic,
    NumericalStatisticSkeleton,
} from './Components/NumericalStatistic';
import { Statistic } from './types';
import { Paragraph, RadioGroup } from '_atoms';
import {
    calculateTotalTimeSpanInMonths,
    ExperienceDateRange,
    findGaps,
} from './helpers';
import { TargetReportSourceType } from '@indicium/common/src/types/Report/SharedTypes';

const mockStatistics: Statistic[] = [
    {
        value: 22,
        unit: 'months',
        showUnit: true,
        title: 'Education Timespan',
        text: 'Here is some long text and some more text and more text and more text and more more more',
    },
    {
        value: 55,
        unit: 'months',
        showUnit: true,
        title: 'Job Timespan',
        text: 'Here is some text',
    },
    {
        value: 3,
        showUnit: false,
        title: 'Number of Gaps',
        text: 'Here is some long text and some more text and more text and more text and more more more',
    },
    {
        value: 3,
        showUnit: false,
        title: 'Present Typos',
        text: 'Here is some text',
    },
];

const allSources: TargetReportSourceType[] = [
    'companyhouse',
    'orbis',
    'pipl',
    'digitalclues',
    'firmenabc',
    'indicium',
    'zefix',
    'complianceCatalyst',
    'icijLeaks',
    'google',
    'shadowdragon',
    'userInput',
];

type GroupSource = 'cv' | 'osint' | 'all';

const sourceLists: Record<GroupSource, TargetReportSourceType[]> = {
    cv: ['userInput'],
    osint: allSources.filter((source) => source !== 'userInput'),
    all: allSources,
};

const calculateStatistics = (data: Target | undefined): Statistic[] => {
    console.log(data);
    // TODO: To be implemented
    return mockStatistics;
};

export const CVAnalysis: FC<ResultsProps> = ({
    targetData,
    targetImages,
    targetLoading,
}) => {
    const { t } = useTranslation();

    const targetFullName = [
        targetData?.firstname,
        targetData?.middlename,
        targetData?.lastname,
    ]
        .filter(Boolean)
        .join(' ');

    const sourceOptions: {
        id: number;
        label: string;
        value: GroupSource;
    }[] = [
        // TODO: Add translations for the labels
        { id: 1, label: 'CV parsed data', value: 'cv' },
        { id: 2, label: 'OSINT generated CV', value: 'osint' },
        { id: 3, label: 'All', value: 'all' },
    ];

    const [activeSourcesGroup, setActiveSourcesGroup] =
        useState<GroupSource>('cv');

    const activeSources = sourceLists[activeSourcesGroup];

    const handleSelectedSourcesGroup = (value: GroupSource) => {
        setActiveSourcesGroup(value);
    };

    const statistics = useMemo(() => {
        return calculateStatistics(targetData);
    }, [targetData]);

    // TODO: set the flag to true when userInput is selected as an active source by the filters
    const isUserInputActiveSource =
        activeSources.includes('userInput') && activeSources.length === 1;

    const educationBySource =
        targetData?.profile?.data.educations?.filter((education) =>
            isUserInputActiveSource
                ? education.sources.includes('userInput')
                : education.sources.some((source) => source !== 'userInput'),
        ) ?? [];

    const workExperienceBySource = targetData?.entrepreneurialActivities
        ? Object.entries(targetData?.entrepreneurialActivities)
              .filter(([source]) =>
                  isUserInputActiveSource ? source === 'userInput' : true,
              )
              .flatMap(([, workExperience]) => workExperience)
        : [];

    const educationRangesBySource =
        educationBySource.map((education) => ({
            start: education.value.date_range?.start,
            end: education.value.date_range?.end ?? targetData?.createdAt,
        })) ?? [];

    const workExperienceRangesBySource = workExperienceBySource.map(
        (workExperience) => ({
            start: workExperience.from,
            end: workExperience.to ?? targetData?.createdAt,
        }),
    );

    const validEducationRanges = educationRangesBySource.filter(
        (experience): experience is ExperienceDateRange =>
            Boolean(experience.start && experience.end),
    );

    const validWorkExperienceRanges = workExperienceRangesBySource.filter(
        (experience): experience is ExperienceDateRange =>
            Boolean(experience.start && experience.end),
    );

    const experienceGaps = findGaps(
        [...validEducationRanges, ...validWorkExperienceRanges],
        1,
    );

    console.log(experienceGaps);

    const totalWorkExperienceTimeSpan = calculateTotalTimeSpanInMonths(
        validWorkExperienceRanges,
    );
    const totalEducationTimeSpan =
        calculateTotalTimeSpanInMonths(validEducationRanges);

    console.log(totalWorkExperienceTimeSpan, totalEducationTimeSpan);

    const uniqueCompanies = Array.from(
        new Set(workExperienceBySource.flatMap((work) => work.companyName)),
    );

    console.log(uniqueCompanies);

    return (
        <DefaultPageLayout
            title={t('cVAnalysis.title')}
            headerActions={
                <ProfileInfo
                    name={targetFullName}
                    createdAt={targetData?.profile?.createdAt}
                    imageUrl={targetImages[0]}
                />
            }
        >
            <div className="flex flex-row gap-8 items-center justify-center pb-4">
                <Paragraph
                    color={'light'}
                    weight={'bold'}
                    size={'small'}
                    className="font-jost"
                >
                    {/*TODO add translation*/}
                    {'Select the source:'}
                </Paragraph>
                <RadioGroup
                    options={sourceOptions}
                    onSelect={(value) =>
                        handleSelectedSourcesGroup(value as GroupSource)
                    }
                    selected={activeSourcesGroup}
                />
            </div>
            <Paragraph className="pb-3" color={'light'} size={'small'}>
                {`*Selected sources: [ ${activeSources.join(', ')}]`}
            </Paragraph>
            <div className={'grid grid-cols-3 gap-4 flex-grow overflow-y-auto'}>
                {statistics
                    .map((props, index) => (
                        <NumericalStatistic key={index} {...props} />
                    ))
                    .concat(
                        targetLoading
                            ? Array(4)
                                  .fill(null)
                                  .map((_, index) => (
                                      <NumericalStatisticSkeleton
                                          key={index}
                                          index={index}
                                      />
                                  ))
                            : [],
                    )}
            </div>
            <div className="pt-3">
                <Paragraph color={'light'} className={'center'}>
                    {
                        '* These are some mock statistics just to get an idea of how things will look like'
                    }
                </Paragraph>
            </div>
        </DefaultPageLayout>
    );
};
