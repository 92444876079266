import {
    differenceInDays,
    differenceInMonths,
    isAfter,
    max,
    parseISO,
} from 'date-fns';

export type ExperienceDateRange = Record<'start' | 'end', string>;

const _differenceInMonths = (earlierDate: Date, laterDate: Date): number => {
    // Ensure earlier date is actually the earlier date
    if (earlierDate > laterDate) {
        [earlierDate, laterDate] = [laterDate, earlierDate];
    }

    const totalDays = differenceInDays(laterDate, earlierDate);
    const months = totalDays / 30.44; // Average number of days in a month

    return parseFloat(months.toFixed(2));
};

const _sortRangesByStartAsc = (
    dateRanges: ExperienceDateRange[],
): ExperienceDateRange[] =>
    dateRanges.sort(
        (a, b) => parseISO(a.start).getTime() - parseISO(b.start).getTime(),
    );

export const findGaps = (
    dateRanges: ExperienceDateRange[],
    gapThresholdInMonths: number,
): ExperienceDateRange[] => {
    const sortedRanges = _sortRangesByStartAsc(dateRanges);

    const gaps: ExperienceDateRange[] = [];

    sortedRanges.forEach((currentRange, index) => {
        const nextRange = sortedRanges.at(index + 1);

        if (!nextRange) {
            return;
        }

        const monthsDifference = differenceInMonths(
            parseISO(nextRange.start),
            parseISO(currentRange.end),
        );

        const isNextGreater = isAfter(
            parseISO(nextRange.start),
            parseISO(currentRange.start),
        );

        if (isNextGreater && monthsDifference >= gapThresholdInMonths) {
            gaps.push({
                start: currentRange.end,
                end: nextRange.start,
            });
        }
    });

    return gaps;
};

export const calculateTotalTimeSpanInMonths = (
    dateRanges: ExperienceDateRange[],
): number => {
    const sortedRanges = _sortRangesByStartAsc(dateRanges).map(
        ({ start, end }) => ({
            start: parseISO(start),
            end: parseISO(end),
        }),
    );

    const mergedRanges: Record<'start' | 'end', Date>[] = [];

    sortedRanges.forEach((currentRange) => {
        const lastMerged = mergedRanges.at(-1);

        if (lastMerged && currentRange.start < lastMerged.end) {
            mergedRanges[mergedRanges.length - 1] = {
                start: lastMerged.start,
                end: max([currentRange.end, lastMerged.end]),
            };

            return;
        }

        mergedRanges.push(currentRange);
    });

    return mergedRanges.reduce(
        (total, range) => total + _differenceInMonths(range.end, range.start),
        0,
    );
};
