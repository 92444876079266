import { FC } from 'react';
import classnames from 'classnames';
import { Paragraph } from '_atoms';

type RadioGroupOption = {
    label: string;
    value: string;
};

type RadioGroupProps = {
    className?: string;
    options: RadioGroupOption[];
    selected?: string;
    onSelect?: (value: string) => void;
    disabled?: boolean;
};

export const RadioGroup: FC<RadioGroupProps> = ({
    className,
    options,
    selected,
    onSelect,
    disabled = false,
}: RadioGroupProps) => {
    return (
        <div
            className={classnames(
                'flex flex-row gap-5 items-center',
                className,
                disabled && 'pointer-events-none opacity-50',
            )}
        >
            {options.map(({ label, value }, index) => (
                <div
                    className={classnames(
                        'flex flex-row items-center gap-2 cursor-pointer',
                    )}
                    key={index}
                    onClick={() => {
                        if (onSelect && !disabled) {
                            onSelect(value);
                        }
                    }}
                >
                    <span
                        className={classnames(
                            'flex items-center justify-center flex-shrink-0 h-4 w-4 cursor-pointer rounded-full',
                            selected === value
                                ? 'bg-primary-4'
                                : 'bg-neutral-100 ',
                        )}
                    >
                        <span className="rounded-full bg-neutral-200 w-1 h-1" />
                    </span>
                    <Paragraph color={selected === value ? 'blue' : 'light'}>
                        {label}
                    </Paragraph>
                </div>
            ))}
        </div>
    );
};
